import React from "react"
import styled from "@emotion/styled"
import Project from "./project"
import Reveal from "./reveal"

const StyledProjects = styled.h4``

const StyledProjectType = styled.h3`
  text-transform: uppercase;
  letter-spacing: 4px;
`

const StyledProject = styled(Project)`
  margin: 15% 0;
  &:last-child {
    margin-bottom: 0;
  }
  @media (min-width: 768px) {
    margin: 10% 0;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const ProjectsWrapper = styled.section`
  margin: 15% 0;
  section {
    margin-top: 0;
  }

  @media (min-width: 768px) {
    margin: 5% 0;
  }
`
function Projects({ title, categoryTitle, projects, className }) {
  return (
    <ProjectsWrapper className={`bg-white ${className}`}>
      <div className="container-fluid">
        {title && categoryTitle && (
          <div className="row">
            <div className="col-12 text-center">
              <Reveal>
                {title && (
                  <StyledProjects className="display-2 font-weight-bolder mb-1 mb-md-2">
                    {title}
                  </StyledProjects>
                )}
                {categoryTitle && (
                  <StyledProjectType className="h6 font-weight-normal mb-1 mb-md-2 mb-lg-4">
                    {categoryTitle}
                  </StyledProjectType>
                )}
              </Reveal>
            </div>
          </div>
        )}

        <div className="row">
          <div className="col-12">
            {projects &&
              projects.map((project, i) => {
                return (
                  <StyledProject
                    reverse={i % 2 === 0}
                    key={i}
                    project={project.node}
                  ></StyledProject>
                )
              })}
          </div>
        </div>
      </div>
    </ProjectsWrapper>
  )
}

Projects.defaultProps = {}

Projects.propTypes = {}

export default Projects
